
import React from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Layout from "../components/layout"
import Seo from "../components/seo"

import KeyVisual from '../components/key-visual';
import SitemapContent from '../components/sitemap-content';

import * as styles from  './sitemap.module.scss'

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}
const Sitemap = () => {
  return (
    <Layout headerCheck={false} page="sitemap">
      <Seo
        title="サイトマップ | becoz (ビコーズ)"
        description="DATAFLUCT (データフラクト) が運営する becoz (ビコーズ) のブランドサイト。becoz はデータサイエンスの力で環境価値を見える化し、カーボンニュートラルに向けた行動を促進する環境価値流通プラットフォームです。"
      />

      <KeyVisual
        titleEnglish={'Sitemap'}
        titleJapanese={'サイトマップ'}
      />

      <section className={`section ${styles.sectionSitemap}`}>
        <SitemapContent
          title={'TOP'}
          titleLink={'/'}
          isBlank={false}
          contents={[
            {
              "contentHeadline": "わたしたちについて",
              "contentLink": "/about",
              "isBlank": false
            },
            {
              "contentHeadline": "ニュース",
              "contentLink": "/news",
              "isBlank": false
            }
          ]}
        />
      </section>
    </Layout>
  )
}
export default Sitemap