import * as React from "react"
import PropTypes from "prop-types"
import {StaticImage} from "gatsby-plugin-image";

const KeyVisual = (props) => (
  <div className="kv">
    <div className="kv__background">
      <StaticImage
        src="../images/common/kv_background_pc.jpg"
        alt=""
        style={{ height :"100%"}}
        className="pc-only"
      />
      <StaticImage
        src="../images/common/kv_background_sp.jpg"
        alt=""
        style={{ height :"100%"}}
        className="sp-only"
      />
    </div>
    <div className="kv-titles">
      <h1 className="kv-title__eng">{props.titleEnglish}</h1>
      <h3 className="kv-title__jpn">{props.titleJapanese}</h3>
    </div>
  </div>
)

KeyVisual.propTypes = {
  titleEnglish: PropTypes.string,
  titleJapanese: PropTypes.string
}

KeyVisual.defaultProps = {
  titleEnglish: ``,
  titleJapanese: ``
}

export default KeyVisual
