import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import * as styles from  './sitemap-content.module.scss'

const SitemapContent = (props) => (
  <>
    {
      props.title ? <Link className={styles.siteTitle} href={props.titleLink} target={props.isBlank ? "_blank" : "_self"}>{props.title}</Link> : ""
    }
    <div className={styles.sitemapWrapper}>
      <div className={styles.siteConetnts}>
        <ul>
          {
            props.contents.map( (siteContent) => {
              return <li><Link href={siteContent.contentLink} target={siteContent.isBlank ? "_blank" : "_self"}>{siteContent.contentHeadline}</Link></li>;
            })
          }
        </ul>
      </div>
    </div>
  </>
)

SitemapContent.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.array
}

SitemapContent.defaultProps = {
  title: ``,
  titleLink: ``,
  isBlank: false,
  contents: [
    {
      "contentHeadline": "",
      "contentLink": "",
      "isBlank": false
    }
  ]
}

export default SitemapContent
